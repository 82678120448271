<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">Choose A New Customer</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="customer-form">
          <v-select
            label="Customer *"
            v-model="fields.customer_id"
            :items="customers"
            item-value="id"
            item-text="full_name"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('customer_id')"
            :error-messages="errors['customer_id']"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="customer-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    property: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      fields: {
        customer_id: null,
      },
      errors: {},
    };
  },

  methods: {
    open() {
      this.fields.customer_id = this.property.customer.id;

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        propertyId: this.$route.params.propertyId,
        fields: this.fields,
      };

      this.$store
        .dispatch("melrose/propertiesStore/saveCustomerProperty", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.fields = {
        customer_id: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },

  computed: {
    customers() {
      return this.$store.getters["melrose/customersStore/all"];
    },
  },
};
</script>
