<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Viewing
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="viewing-form">
          <v-select
            label="Customer *"
            v-model="fields.customer_id"
            :items="customers"
            item-text="full_name"
            item-value="id"
            outlined
            :disabled="editingFromCustomer"
            :error="errors.hasOwnProperty('customer_id')"
            :error-messages="errors['customer_id']"
            background-color="white"
          >
            <template v-slot:prepend-item>
              <div class="pa-3">
                <v-text-field
                  label="Search Customer"
                  v-model="customerSearchTerm"
                  outlined
                  autofocus
                  dense
                  hide-details
                ></v-text-field>
              </div>
              <v-divider></v-divider>
            </template>
          </v-select>

          <v-select
            label="Property *"
            v-model="fields.property_id"
            :items="properties"
            item-value="id"
            item-text="address.short"
            outlined
            background-color="white"
            :disabled="editingFromProperty"
            :error="errors.hasOwnProperty('property_id')"
            :error-messages="errors['property_id']"
          ></v-select>
          <v-select
            label="Viewings Performed By? *"
            v-model="fields.viewing_performed_by"
            :items="performed_by"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('viewing_performed_by')"
            :error-messages="errors['viewing_performed_by']"
          ></v-select>
          <v-text-field
            label="Viewing Date *"
            v-model="fields.viewing_date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('viewing_date')"
            :error-messages="errors['viewing_date']"
          ></v-text-field>
          <v-select
            label="Status *"
            v-model="fields.status"
            :items="statuses"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('status')"
            :error-messages="errors['status']"
          ></v-select>
          <v-text-field
            label="Follow Up Date"
            v-model="fields.follow_up_date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('follow_up_date')"
            :error-messages="errors['follow_up_date']"
          ></v-text-field>
          <v-switch
            label="Feedback Received?"
            v-model="fields.feedback"
            inset
            :error="errors.hasOwnProperty('feedback')"
            :error-messages="errors['feedback']"
          ></v-switch>
          <v-textarea
            label="Notes"
            v-model="fields.notes"
            rows="5"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('notes')"
            :error-messages="errors['notes']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="viewing-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    editingFromProperty: {
      type: Boolean,
      default: false,
      required: false,
    },
    editingFromCustomer: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      customerSearchTerm: "",
      dialog: false,
      viewing_date: false,
      loading: false,
      isEditing: false,
      viewing: {},
      fields: {
        viewing_date: null,
        viewing_performed_by: null,
        property_id: null,
        customer_id: null,
        status: null,
        follow_up_date: null,
        feedback: false,
        notes: null,
      },
      errors: {},
      performed_by: ["Estate Agent", "Customer"],
      statuses: [
        "Requested",
        "Confirmed",
        "Awaiting Follow Up",
        "Needs Followed Up",
        "Complete",
      ],
    };
  },

  methods: {
    open(viewing = null) {
      if (viewing !== null) {
        this.viewing = viewing;
        this.isEditing = true;
        this.fields.customer_id = viewing.enquiry
          ? viewing.enquiry.customer.id
          : null;

        this.fields.property_id = viewing.property
          ? viewing.property.id
          : parseInt(this.$route.params.propertyId);

        this.fields.viewing_performed_by = viewing.viewing_performed_by;
        this.fields.viewing_date = viewing.viewing_date;
        this.fields.status = viewing.status;
        this.fields.follow_up_date = viewing.follow_up_date;
        this.fields.feedback = viewing.feedback;
        this.fields.notes = viewing.notes;
      } else {
        const propertyId = this.$route.params.propertyId;
        if (propertyId) {
          this.fields.property_id = parseInt(this.$route.params.propertyId);
        }
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.editingFromProperty) {
        payload.fromProperty = true;
      }

      if (this.isEditing) {
        payload.viewingId = this.viewing.id;
      }

      this.$store
        .dispatch("melrose/propertiesStore/saveViewing", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.viewing = {};
      this.fields = {
        viewing_date: null,
        viewing_performed_by: null,
        property_id: null,
        customer_id: null,
        status: null,
        follow_up_date: null,
        feedback: false,
        notes: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },

  computed: {
    customers() {
      let customers = this.$store.getters["melrose/customersStore/all"];

      if (this.customerSearchTerm !== "") {
        customers = customers.filter((c) => {
          const name = c.full_name.toLowerCase();
          const customerSearchTerm = this.customerSearchTerm.toLowerCase();
          return name.includes(customerSearchTerm);
        });
      }

      return customers;
    },

    property() {
      return this.$store.getters["melrose/propertiesStore/get"];
    },

    properties() {
      return this.$store.getters["melrose/propertiesStore/all"];
    },
  },
};
</script>
