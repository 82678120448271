<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">Edit Property Details </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form
          @submit.prevent="save"
          method="post"
          id="details-individual-form"
        >
          <v-select
            label="Type *"
            v-model="fields.type"
            :items="types"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('type')"
            :error-messages="errors['type']"
          ></v-select>
          <v-select
            label="Status *"
            v-model="fields.status"
            :items="statuses"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('status')"
            :error-messages="errors['status']"
          ></v-select>

          <v-text-field
            label="Closing Date"
            v-model="fields.closing_date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('closing_date')"
            :error-messages="errors['closing_date']"
          ></v-text-field>

          <v-text-field
            label="Total Bedrooms *"
            v-model="fields.total_bed_rooms"
            type="number"
            step="1"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('total_bed_rooms')"
            :error-messages="errors['total_bed_rooms']"
          ></v-text-field>
          <v-text-field
            label="Total Reception Rooms *"
            v-model="fields.total_reception_rooms"
            type="number"
            step="1"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('total_reception_rooms')"
            :error-messages="errors['total_reception_rooms']"
          ></v-text-field>
          <v-text-field
            label="Total Bathrooms *"
            v-model="fields.total_bath_rooms"
            type="number"
            step="1"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('total_bath_rooms')"
            :error-messages="errors['total_bath_rooms']"
          ></v-text-field>
          <v-switch
            label="Has Garden?"
            v-model="fields.has_garden"
            inset
            :error="errors.hasOwnProperty('has_garden')"
            :error-messages="errors['has_garden']"
          ></v-switch>
          <v-switch
            label="Has Garage?"
            v-model="fields.has_garage"
            inset
            :error="errors.hasOwnProperty('has_garage')"
            :error-messages="errors['has_garage']"
          ></v-switch>
          <v-select
            label="Council Tax Band *"
            v-model="fields.council_tax_band"
            :items="councils"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('council_tax_band')"
            :error-messages="errors['council_tax_band']"
          ></v-select>
          <v-select
            label="EPC"
            v-model="fields.epc"
            :items="epc_types"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('epc')"
            :error-messages="errors['epc']"
          ></v-select>
          <v-file-input
            label="Energy Performance Document"
            v-model="fields.energy_performance_document"
            accept="application/pdf"
            placeholder="Choose a File"
            outlined
            @change="documentHasChanged()"
            :error="errors.hasOwnProperty('energy_performance_document')"
            :error-messages="errors['energy_performance_document']"
          ></v-file-input>
          <v-text-field
            label="viewings performed by*"
            v-model="fields.viewings_performed_by"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('viewings_performed_by')"
            :error-messages="errors['viewings_performed_by']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="details-individual-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      property: {},
      fields: {
        type: null,
        epc: null,
        status: null,
        total_bed_rooms: null,
        total_reception_rooms: null,
        total_bath_rooms: null,
        has_garden: false,
        has_garage: false,
        council_tax_band: null,
        energy_performance_document: null,
        viewings_performed_by: null,
        energy_performance_document_has_changed: false,
        closing_date: null,
      },

      types: [
        "House",
        "Flat/Apartment",
        "Bungalow",
        "Land",
        "Commercial Property",
        "Barn Conversion",
        "Cottage",
        "Other",
      ],
      epc_types: ["A", "B", "C", "D", "E", "F", "G"],
      councils: ["A", "B", "C", "D", "E", "F", "G", "H", "N/A"],
      price_types: ["Offers Over", "Fixed Price"],
      statuses: [
        "For Sale",
        "Under Offer",
        "Sold STC",
        "STCM",
        "Reserved",
        "Complete",
      ],
      errors: {},
    };
  },

  methods: {
    open(property = null) {
      if (property !== null) {
        this.property = property;
        this.fields.type = property.type;
        this.fields.epc = property.epc;
        this.fields.status = property.status;
        this.fields.total_bed_rooms = property.total_bed_rooms;
        this.fields.total_reception_rooms = property.total_reception_rooms;
        this.fields.total_bath_rooms = property.total_bath_rooms;
        this.fields.has_garden = property.has_garden;
        this.fields.has_garage = property.has_garage;
        this.fields.council_tax_band = property.council_tax_band;
        this.fields.viewings_performed_by = property.viewings_performed_by;
        this.fields.closing_date = property.closing_date;

        this.fields.energy_performance_document =
          property.energy_performance_document
            ? new File(
                [property.energy_performance_document],
                property.energy_performance_document
              )
            : null;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        propertyId: this.$route.params.propertyId,
        fields: this.fields,
      };

      payload.energy_performance_document_has_changed = this.fields
        .energy_performance_document_has_changed
        ? 1
        : 0;

      if (this.fields.energy_performance_document_has_changed) {
        payload.energy_performance_document =
          this.fields.energy_performance_document;
      }

      this.$store
        .dispatch("melrose/propertiesStore/savePropertyDetails", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    documentHasChanged() {
      this.fields.energy_performance_document_has_changed = true;
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.property = {};
      this.fields = {
        type: null,
        epc: null,
        status: null,
        total_bed_rooms: null,
        total_reception_rooms: null,
        total_bath_rooms: null,
        has_garden: false,
        has_garage: false,
        council_tax_band: null,
        viewings_performed_by: null,
        energy_performance_document_has_changed: false,
        energy_performance_document: null,
        closing_date: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
