<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn small depressed :to="{ name: 'module-melrose-properties-all' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Properties</v-btn
        >
      </v-row>
      <v-row align="center">
        <v-col cols="8" class="pr-12">
          <h1>{{ property.address.short }}</h1>
        </v-col>
        <v-col cols="4" class="text-right pt-3">
          <v-row justify="end" no-gutters>
            <v-col cols="12">
              <v-btn dark small depressed @click="downloadPdf">
                <v-icon left>mdi-file-pdf-box</v-icon>Download as PDF
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-3">Viewings</v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :headers="viewingTableHeaders"
              :items="property.viewings"
              show-expand
              no-data-text="No Viewings found"
            >
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    name: 'module-melrose-customers-individual',
                    params: { customerId: item.customer.id },
                  }"
                  >{{ item.customer.full_name }}</router-link
                >
              </template>
              <template v-slot:item.feedback_received="{ item }">
                <v-chip
                  label
                  small
                  color="success"
                  v-if="item.feedback_received"
                  >Yes</v-chip
                >
                <v-chip label small color="error" v-else>No</v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.viewingDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pt-4 pb-4">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <th>Notes</th>
                        <td v-html="item.notes" class="pt-4 pb-4"></td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </template>
            </v-data-table>
          </v-card>
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-3"
              >Notes of Interest</v-card-title
            >
            <v-divider></v-divider>
            <v-data-table
              :headers="notesOfInterestTableHeaders"
              :items="property.notes_of_interest"
              show-expand
              no-data-text="No Notes of Interest found"
            >
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    name: 'module-melrose-customers-individual',
                    params: { customerId: item.customer.id },
                  }"
                  >{{ item.customer.full_name }}</router-link
                >
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.noteOfInterestDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pt-4 pb-4">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <th>Notes</th>
                        <td v-html="item.notes" class="pt-4 pb-4"></td>
                      </tr>
                      <tr>
                        <th>Solicitor</th>
                        <td class="pt-4 pb-4">
                          <div>{{ item.solicitor.name }}</div>
                          <div>
                            <strong>Phone:</strong> {{ item.solicitor.phone }}
                          </div>
                          <div>
                            <strong>Email:</strong> {{ item.solicitor.email }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </template>
            </v-data-table>
          </v-card>
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-3">Offers</v-card-title>
            <v-divider></v-divider>
            <v-data-table
              :headers="offerTableHeaders"
              :items="property.offers"
              show-expand
              no-data-text="No Offers found"
            >
              <template v-slot:item.customer="{ item }">
                <router-link
                  :to="{
                    name: 'module-melrose-customers-individual',
                    params: { customerId: item.customer.id },
                  }"
                  >{{ item.customer.full_name }}</router-link
                >
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.offerDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pt-4 pb-4">
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <th>Notes</th>
                        <td v-html="item.notes" class="pt-4 pb-4"></td>
                      </tr>
                      <tr>
                        <th>Solicitor</th>
                        <td class="pt-4 pb-4">
                          <div>{{ item.solicitor.name }}</div>
                          <div>
                            <strong>Phone:</strong> {{ item.solicitor.phone }}
                          </div>
                          <div>
                            <strong>Email:</strong> {{ item.solicitor.email }}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </template>
            </v-data-table>
          </v-card>
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-4">Images/Videos</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row dense>
                <v-col
                  cols="3"
                  v-for="image in property.images"
                  :key="image.id"
                >
                  <v-card>
                    <v-img
                      :src="image.url"
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      height="200px"
                      contain
                    >
                      <v-overlay
                        v-if="image.id === property.main_image.id"
                        absolute
                        color="rgba(0,0,0,0)"
                        class="justify-end align-start flex-grow-1"
                      >
                        <v-chip label color="accent" class="mt-4 mr-4"
                          >Main Image</v-chip
                        >
                      </v-overlay>
                      <v-card-title v-text="image.name"></v-card-title>
                    </v-img>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            x-small
                            depressed
                            color="blue lighten-4 blue--text"
                            v-on="on"
                            class="mr-2"
                          >
                            <v-icon x-small>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            x-small
                            depressed
                            color="red lighten-4 red--text"
                            v-on="on"
                          >
                            <v-icon x-small>mdi-archive</v-icon>
                          </v-btn>
                        </template>
                        <span>Archive</span>
                      </v-tooltip>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Property Admin</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.PropertyAdminDialog.open(property)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Property's Admin</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>Accom Viewings</th>
                    <td>{{ property.accom_viewings ? "Yes" : "No" }}</td>
                  </tr>
                  <tr>
                    <th>Caseload Ref</th>
                    <td>{{ property.caseload_ref }}</td>
                  </tr>
                  <tr>
                    <th>Key Info</th>
                    <td>{{ property.key_info }}</td>
                  </tr>
                  <tr>
                    <th>Managing Office</th>
                    <td>{{ property.managing_office }}</td>
                  </tr>
                  <tr>
                    <th>Property Manager</th>
                    <td>
                      {{ property.user_menager.full_name }}
                    </td>
                  </tr>
                  <tr>
                    <th>For Sale Board</th>
                    <td>{{ property.for_sale_board }}</td>
                  </tr>
                  <tr>
                    <th>Featured Property</th>
                    <td>
                      {{ property.featured_property ? "Yes" : "No" }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Property Information</div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-card outlined class="mb-6">
                <v-card-subtitle
                  class="d-flex justify-start align-center grey lighten-5"
                >
                  <div class="flex-grow-1">Address</div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        depressed
                        color="blue lighten-4 blue--text"
                        v-on="on"
                        @click="$refs.addressDialog.open(property.address)"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Address</span>
                  </v-tooltip>
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-simple-table>
                  <tbody>
                    <tr>
                      <th>Line 1</th>
                      <td>{{ property.address.address_1 }}</td>
                    </tr>
                    <tr>
                      <th>Line 2</th>
                      <td>{{ property.address.address_2 }}</td>
                    </tr>
                    <tr>
                      <th>City</th>
                      <td>{{ property.address.city }}</td>
                    </tr>
                    <tr>
                      <th>County</th>
                      <td>{{ property.address.county }}</td>
                    </tr>
                    <tr>
                      <th>Postcode</th>
                      <td>{{ property.address.postcode }}</td>
                    </tr>
                    <tr>
                      <th>Country</th>
                      <td>{{ property.address.country }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
              <v-card outlined class="mb-6">
                <v-card-subtitle
                  class="d-flex justify-start align-center grey lighten-5"
                >
                  <div class="flex-grow-1">Sellers Details</div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        depressed
                        color="blue lighten-4 blue--text"
                        v-on="on"
                        @click="$refs.customerDialog.open()"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Customer</span>
                  </v-tooltip>
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-simple-table>
                  <tbody>
                    <tr>
                      <th>Full Name</th>
                      <td>
                        <router-link
                          :to="{
                            name: 'module-melrose-customers-individual',
                            params: { customerId: property.customer.id },
                          }"
                          >{{ property.customer.full_name }}</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{{ property.customer.email }}</td>
                    </tr>
                    <tr>
                      <th>Phone</th>
                      <td>{{ property.customer.phone }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
              <v-card outlined>
                <v-card-subtitle
                  class="d-flex justify-start align-center grey lighten-5"
                >
                  <div class="flex-grow-1">Property Details</div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        depressed
                        color="blue lighten-4 blue--text"
                        v-on="on"
                        @click="$refs.propertyDetailsDialog.open(property)"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Property's Details</span>
                  </v-tooltip>
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-simple-table>
                  <tbody>
                    <tr>
                      <th>Type</th>
                      <td>{{ property.type }}</td>
                    </tr>
                    <tr>
                      <th>Price Type</th>
                      <td>{{ property.price_type }}</td>
                    </tr>
                    <tr>
                      <th>Current Price</th>
                      <td>{{ property.current_price.formatted_price }}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>{{ property.status }}</td>
                    </tr>
                    <tr>
                      <th>Total Bedrooms</th>
                      <td>{{ property.total_bed_rooms }}</td>
                    </tr>
                    <tr>
                      <th>Total Reception Rooms</th>
                      <td>{{ property.total_reception_rooms }}</td>
                    </tr>
                    <tr>
                      <th>Total Bathrooms</th>
                      <td>{{ property.total_bath_rooms }}</td>
                    </tr>
                    <tr>
                      <th>Has Garden?</th>
                      <td>{{ property.has_garden ? "Yes" : "No" }}</td>
                    </tr>
                    <tr>
                      <th>Has Garage?</th>
                      <td>{{ property.has_garage ? "Yes" : "No" }}</td>
                    </tr>
                    <tr>
                      <th>Council Tax Band</th>
                      <td>{{ property.council_tax_band }}</td>
                    </tr>
                    <tr>
                      <th>EPC</th>
                      <td>{{ property.epc }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
            </v-card-text>
          </v-card>
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Price History</div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.priceDialog.open()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New Price</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-simple-table>
              <thead>
                <tr>
                  <th>Price</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="price in property.prices" :key="price.id">
                  <td>{{ price.formatted_price }}</td>
                  <td>{{ price.type }}</td>
                  <td>
                    <v-chip
                      label
                      small
                      color="success"
                      v-if="property.current_price.id === price.id"
                      >Current</v-chip
                    >
                    <v-chip label small v-else>Past</v-chip>
                  </td>
                  <td>{{ price.formatted_dates.created_at }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <offer-dialog ref="offerDialog" :editingFromProperty="true" />
    <note-of-interest-dialog
      ref="noteOfInterestDialog"
      :editingFromProperty="true"
    />
    <viewing-dialog ref="viewingDialog" :editingFromProperty="true" />
    <price-dialog ref="priceDialog" />
    <address-dialog ref="addressDialog" />
    <customer-dialog ref="customerDialog" :property="property" />
    <property-details-dialog ref="propertyDetailsDialog" />
    <property-admin-dialog ref="PropertyAdminDialog" />
  </div>
</template>

<script>
import OfferDialog from "./offers/components/OfferDialog.vue";
import NoteOfInterestDialog from "./notes-of-interest/components/NoteOfInterestDialog.vue";
import ViewingDialog from "./viewings/components/ViewingDialog.vue";
import PriceDialog from "./components/PriceDialog.vue";
import AddressDialog from "./components/AddressDialog.vue";
import CustomerDialog from "./components/CustomerDialog.vue";
import PropertyDetailsDialog from "./components/PropertyDetailsDialog.vue";
import PropertyAdminDialog from "./components/PropertyAdminDialog.vue";

export default {
  components: {
    OfferDialog,
    NoteOfInterestDialog,
    ViewingDialog,
    PriceDialog,
    AddressDialog,
    CustomerDialog,
    PropertyDetailsDialog,
    PropertyAdminDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          to: { name: "module-melrose-properties-all" },
          exact: true,
        },
      ],
      viewingTableHeaders: [
        { text: "Viewing Date", value: "formatted_dates.viewing_date" },
        { text: "Customer", value: "customer" },
        { text: "Performed By?", value: "viewings_performed_by" },
        { text: "Status", value: "status" },
        { text: "Follow Up Date", value: "formatted_dates.follow_up_date" },
        { text: "Feedback Received?", value: "feedback_received" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
        { text: "", value: "data-table-expand" },
      ],
      notesOfInterestTableHeaders: [
        { text: "Date", value: "formatted_dates.date" },
        { text: "Customer", value: "customer" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
        { text: "", value: "data-table-expand" },
      ],
      offerTableHeaders: [
        { text: "Date", value: "formatted_dates.date" },
        { text: "Customer", value: "customer" },
        { text: "Status", value: "status" },
        { text: "Amount", value: "formatted_amount" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },

  computed: {
    property() {
      return this.$store.getters["melrose/propertiesStore/get"];
    },

    properties() {
      return this.$store.getters["melrose/propertiesStore/all"];
    },
  },

  methods: {
    downloadPdf() {},
  },

  created() {
    this.breadcrumbs.push({
      text: this.property.address.short,
      disabled: true,
    });
  },
};
</script>
