<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Property Admin
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="admin-details-form">
          <v-switch
            label="Accom Viewings"
            v-model="fields.accom_viewings"
            inset
            :error="errors.hasOwnProperty('accom_viewings')"
            :error-messages="errors['accom_viewings']"
          ></v-switch>
          <v-text-field
            label="Caseload Reference"
            v-model="fields.caseload_reference"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('caseload_reference')"
            :error-messages="errors['caseload_reference']"
          ></v-text-field>
          <v-text-field
            label="Key Info"
            v-model="fields.key_info"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('key_info')"
            :error-messages="errors['key_info']"
          ></v-text-field>
          <v-select
            label="Managing Office"
            v-model="fields.managing_office"
            :items="managing_office_types"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('managing_office')"
            :error-messages="errors['managing_office']"
          ></v-select>
          <v-select
            label="Property Manager"
            v-model="fields.property_manager"
            :items="menagers"
            item-value="id"
            item-text="full_name"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('property_manager')"
            :error-messages="errors['property_manager']"
          ></v-select>
          <v-text-field
            label="For Sale Board"
            v-model="fields.for_sale_board"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('for_sale_board')"
            :error-messages="errors['for_sale_board']"
          ></v-text-field>
          <v-switch
            label="Featured Property"
            v-model="fields.featured_property"
            inset
            :error="errors.hasOwnProperty('featured_property')"
            :error-messages="errors['featured_property']"
          ></v-switch>
          <v-switch
            label="Published"
            v-model="fields.visible"
            inset
            :error="errors.hasOwnProperty('visible')"
            :error-messages="errors['visible']"
          ></v-switch>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="admin-details-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      property: {},
      fields: {
        accom_viewings: false,
        caseload_reference: null,
        key_info: null,
        managing_office: null,
        property_manager: null,
        for_sale_board: null,
        featured_property: false,
        visible: false,
      },
      managing_office_types: ["Duns", "Eyemouth"],
      errors: {},
    };
  },

  methods: {
    open(property = null) {
      if (property !== null) {
        this.property = property;
        this.fields.accom_viewings = property.accom_viewings;
        this.fields.caseload_reference = property.caseload_reference;
        this.fields.key_info = property.key_info;
        this.fields.managing_office = property.managing_office;
        this.fields.property_manager = property.property_manager;
        this.fields.for_sale_board = property.for_sale_board;
        this.fields.featured_property = property.featured_property
          ? property.featured_property
          : 0;
        this.fields.visible = property.visible;

        this.isEditing = true;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        propertyId: this.$route.params.propertyId,
        fields: this.fields,
      };

      this.$store
        .dispatch("melrose/propertiesStore/saveAdminProperty", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.price = {};
      this.fields = {
        accom_viewings: false,
        caseload_reference: null,
        key_info: null,
        managing_office: null,
        property_manager: null,
        for_sale_board: null,
        featured_property: false,
        visible: false,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
  computed: {
    menagers() {
      return this.$store.getters["melrose/propertiesStore/managers"];
    },
  },
};
</script>
