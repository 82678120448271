<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline"> Edit Address </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="address-form">
          <v-text-field
            label="Address Line 1 *"
            v-model="fields.address1"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address1')"
            :error-messages="errors['address1']"
          ></v-text-field>
          <v-text-field
            label="Address Line 2"
            v-model="fields.address2"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('address2')"
            :error-messages="errors['address2']"
          ></v-text-field>
          <v-text-field
            label="City *"
            v-model="fields.city"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('city')"
            :error-messages="errors['city']"
          ></v-text-field>
          <v-text-field
            label="Postcode *"
            v-model="fields.postcode"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('postcode')"
            :error-messages="errors['postcode']"
          ></v-text-field>
          <v-text-field
            label="County"
            v-model="fields.county"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('county')"
            :error-messages="errors['county']"
          ></v-text-field>
          <v-autocomplete
            label="Country *"
            v-model="fields.country"
            :items="availableCountries"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('country')"
            :error-messages="errors['country']"
          ></v-autocomplete>
          <v-text-field
            label="Lat"
            v-model="fields.lat"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('lat')"
            :error-messages="errors['lat']"
          ></v-text-field>
          <v-text-field
            label="Long"
            v-model="fields.long"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('long')"
            :error-messages="errors['long']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="address-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      address: {},
      fields: {
        address1: null,
        address2: null,
        city: null,
        postcode: null,
        county: null,
        country: "United Kingdom",
        lat: null,
        long: null,
      },
      errors: {},
    };
  },

  computed: {
    availableCountries() {
      return Object.values(countries.getNames("en"));
    },
  },

  methods: {
    open(address = null) {
      if (address !== null) {
        this.address = address;
        this.fields.address1 = address.address1;
        this.fields.address2 = address.address2;
        this.fields.city = address.city;
        this.fields.postcode = address.postcode;
        this.fields.county = address.county;
        this.fields.country = address.country;
        this.fields.lat = address.lat;
        this.fields.long = address.long;
      }
      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        addressId: this.address.id,
        fields: this.fields,
      };

      this.$store
        .dispatch("melrose/propertiesStore/saveAddress", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.address = {};
      this.fields = {
        address1: null,
        address2: null,
        city: null,
        postcode: null,
        county: null,
        country: "United Kingdom",
        lat: null,
        long: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
