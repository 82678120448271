<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Offer
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="offer-form">
          <v-select
            label="Customer *"
            v-model="fields.customer_id"
            :items="customers"
            item-value="id"
            item-text="full_name"
            outlined
            background-color="white"
            :disabled="editingFromCustomer"
            :error="errors.hasOwnProperty('customer_id')"
            :error-messages="errors['customer_id']"
          ></v-select>
          <v-select
            label="Property *"
            v-model="fields.property_id"
            :items="properties"
            item-value="id"
            item-text="address.short"
            outlined
            background-color="white"
            :disabled="editingFromProperty"
            :error="errors.hasOwnProperty('property_id')"
            :error-messages="errors['property_id']"
          ></v-select>
          <v-select
            label="Solicitor"
            v-model="fields.solicitor_id"
            :items="solicitors"
            item-value="id"
            item-text="name"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('solicitor_id')"
            :error-messages="errors['solicitor_id']"
          ></v-select>
          <v-text-field
            label="Date *"
            v-model="fields.date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('date')"
            :error-messages="errors['date']"
          ></v-text-field>
          <v-text-field
            label="Amount *"
            v-model="fields.amount"
            type="number"
            step="0.01"
            prefix="£"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('amount')"
            :error-messages="errors['amount']"
          ></v-text-field>
          <v-select
            label="Status *"
            v-model="fields.status"
            :items="statuses"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('status')"
            :error-messages="errors['status']"
          ></v-select>
          <v-textarea
            label="Notes"
            v-model="fields.notes"
            rows="5"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('notes')"
            :error-messages="errors['notes']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="offer-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    editingFromProperty: {
      type: Boolean,
      default: false,
      required: false,
    },
    editingFromCustomer: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      offer: {},
      fields: {
        property_id: null,
        customer_id: null,
        solicitor_id: null,
        date: null,
        amount: null,
        status: null,
        notes: null,
      },
      errors: {},
      statuses: [
        "Submitted",
        "Client Notified",
        "Rejected",
        "Accepted",
        "Back With Customer",
      ],
    };
  },

  methods: {
    open(offer = null) {
      if (offer !== null) {
        this.offer = offer;
        this.isEditing = true;
        this.fields.customer_id = offer.enquiry.customer.id;
        this.fields.property_id = offer.property.id;
        this.fields.solicitor_id = offer.solicitor.id;
        this.fields.amount = offer.amount;
        this.fields.date = offer.date;
        this.fields.status = offer.status;
        this.fields.notes = offer.notes;
      } else {
        const propertyId = this.$route.params.propertyId;
        if (propertyId) {
          this.fields.property_id = parseInt(this.$route.params.propertyId);
        }
      }
      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.offerId = this.offer.id;
      }

      this.$store
        .dispatch("melrose/propertiesStore/saveOffer", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.offer = {};
      this.fields = {
        property_id: null,
        customer_id: null,
        solicitor_id: null,
        date: null,
        amount: null,
        status: null,
        notes: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },

  computed: {
    customers() {
      return this.$store.getters["melrose/customersStore/all"];
    },

    properties() {
      return this.$store.getters["melrose/propertiesStore/all"];
    },

    solicitors() {
      return this.$store.getters["melrose/solicitorsStore/all"];
    },
  },
};
</script>
